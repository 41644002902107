import React, { useState, useEffect, useRef } from 'react';
import Button from '../../elements/button/button';
import PasswordInput from '../../elements/password-input/password-input';
import Modal from '../../common/modal/modal';
import { toast } from 'react-toastify';
import {request,unSubRequest} from '../../../utils/request';
import {clone} from '../../../utils/clone';
import useUserState from '../../../store/user';
import { Link } from 'react-router-dom';

const PaymentDetails = () => {

  const {user, setUser} = useUserState();

  const changeInfoTypes = {
    username: {display:"Username"},
    email: {display:"Email"},
    password: {display:"Password"},
  }

  const [lastOpenType,setLastOpenType] = useState(changeInfoTypes.username.display);

  const changeInfoInputRef = useRef(null);
  const changeInfoPasswordInputRef = useRef(null);

  const [changeInfoValue,setChangeInfoValue] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [edittingUsername,setEdittingUsername] = useState(false);
  const [edittingEmail,setEdittingEmail] = useState(false);
  const [edittingPassword,setEdittingPassword] = useState(false);
  const [changeInfoLoading,setChangeInfoLoading] = useState(false);
  const [showModal,setShowModal] = useState(false);
  const [changeInfoButtonStatus, setChangeInfoButtonStatus] = useState("save");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    return () => {
      unSubRequest("user");
    }
  },[]);

  useEffect(() => {
    setShowModal((edittingUsername || edittingEmail || edittingPassword));
  },[edittingUsername, edittingEmail, edittingPassword]);

  useEffect(() => {
    setChangeInfoButtonStatus((changeInfoLoading) ? "loading" : "save");
  },[changeInfoLoading]);

  const formHandler = (e) => {
    e.preventDefault();
    if(changeInfoValue === ""){ return false; }
    if(confirmPassword === "" && user.passSet){ return false; }

    const data = { password: confirmPassword };
    if(edittingUsername){
      data.username = changeInfoValue;
    }else if(edittingEmail){
      data.email = changeInfoValue;
    }else if(edittingPassword){
      data.newPassword = changeInfoValue;
    }

    setErrorMessage("");
    setChangeInfoLoading(true);
    request("user","/user","PATCH", data, {
      then: function(res){
        var message = "Success!";
        if(edittingUsername){
          message = "Username Changed";
          const cloneUser = clone(user);
          cloneUser.username = changeInfoValue;
          setUser(cloneUser);
        }
        if(edittingPassword){
          message = "Password Changed";
          const cloneUser = clone(user);
          cloneUser.passSet = true;
          setUser(cloneUser);
        }
        if(edittingEmail){
          message = "An email has been sent to \""+data.email+"\", please check it and proceed from there. ";
        }
        toast.success(message);
        handleModalClose();
      },
      catch: function(err){
        toast.error(err.message);
      },
      finally: function(){
        setChangeInfoLoading(false);
      }
    });
  }

  const handleModalClose = () => {
    setErrorMessage("");
    setChangeInfoLoading(false);
    setChangeInfoValue("");
    setConfirmPassword("");
    setEdittingUsername(false);
    setEdittingEmail(false);
    setEdittingPassword(false);
    setShowModal(false);
  }


  return (
    <div className="profile-details">
      <h3>Profile</h3>
      <div>
        <div className="profile-detail">
          <label>Username</label>
          <div>
            <input type="text" disabled={true}  value={user.username}/>
            <Button type="button" status="edit" 
              onClick={() => {
                  setEdittingUsername(true);
                  setLastOpenType(changeInfoTypes.username.display);
                }}>
                Edit
            </Button>
          </div>
        </div>
        <div className="profile-detail">
          <label>Email</label>
          <div>
            <input type="text" disabled={true}  value={user.email}/>
            <Button type="button" status="edit" 
              onClick={() => {
                  setEdittingEmail(true);
                  setLastOpenType(changeInfoTypes.email.display);
                }}>
                Edit
            </Button>
          </div>
        </div>
        <div className="profile-detail">
          <label>Password</label>
          <div>
            <input type="text" disabled={true}  value="••••••••"/>
            <Button type="button" status="edit" 
              onClick={() => {
                  setEdittingPassword(true);
                  setLastOpenType(changeInfoTypes.password.display);
                }}>
                Edit
            </Button>
          </div>
        </div>
      </div>
      
      <Modal className="change-info-modal" show={showModal} handleClose={() => {handleModalClose()}}>
        <div className="change-info">
          <form onSubmit={formHandler}>
            <h3>{(!user.passSet && lastOpenType === changeInfoTypes.password.display)? "Set" : "Change your"} {lastOpenType}</h3>
            {(errorMessage !== "")?
              <ul className="errors">
                <li>{errorMessage}</li>
              </ul>
            :""}

            {(lastOpenType !== changeInfoTypes.password.display) ?
              <div>
                <label>New {lastOpenType}</label>
                <input 
                  type={(edittingEmail) ? "email" : "text"}
                  placeholder={"New "+lastOpenType}
                  disabled={changeInfoLoading}
                  ref={changeInfoInputRef}
                  onChange={(e) => {setChangeInfoValue(e.target.value);}}
                  value={changeInfoValue} />
              </div> 
            :""}

            {
              (user.passSet) ? 
              <div>
                <label>{(lastOpenType === changeInfoTypes.password.display)?"Old ":""}Password<span className="forgot-password"><Link tabIndex="-1" to="/reset-password">Forgot?</Link></span></label>
                <PasswordInput 
                  onChange={(e) => {setConfirmPassword(e.target.value);}}
                  disabled={changeInfoLoading}
                  value={confirmPassword} 
                  placeholder={((lastOpenType === changeInfoTypes.password.display)?"Old ":"")+"Password"}
                  passRef={changeInfoPasswordInputRef} />
              </div>: ""
            }
          
            {(lastOpenType === changeInfoTypes.password.display)?
              <div>
                <label>New Password</label>
                <PasswordInput 
                  disabled={changeInfoLoading}
                  onChange={(e) => {setChangeInfoValue(e.target.value);}}
                  placeholder={"New Password"}
                  value={changeInfoValue} />
              </div>
            :""}
            <Button type="submit" status={changeInfoButtonStatus} value={"Set New "+lastOpenType} />
          </form>
        </div>
      </Modal>

      
    </div>
  );

};

export default PaymentDetails;